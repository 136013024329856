function VideoLoader() {
  if (document.querySelectorAll('[js-hook-video]').length) {
    return Promise.all([
      import(/* webpackChunkName: "js/Video-Platforms" */ '@components/video/platforms'),
      import(/* webpackChunkName: "js/Video" */ '@components/video'),
    ])
  }
  return Promise.reject(new Error('No video element found'))
}

export default VideoLoader()
