export default (additionalScripts) => {
  if (additionalScripts) {
    additionalScripts.forEach((file) => {
      switch (file) {
        case 'adyen-payment':
          import(/* webpackChunkName: "js/adyen-payment" */ '~/cartridges/int_adyen_designholding_custom/cartridge/client/default/js/pages/adyen-payment.js');
          break;
        case 'billing-form':
          import(/* webpackChunkName: "js/billing-form" */ '~/cartridges/int_adyen_designholding_custom/cartridge/client/default/js/pages/billing-form.js');
          break;
        case 'payflow-billing-form':
          import(/* webpackChunkName: "js/payflow-billing-form" */ '~/cartridges/int_payflow/cartridge/client/default/js/pages/payflow-billing-form.js');
          break;
        default:
          if (process.env.NODE_ENV !== 'production') {
            console.error(`Unknown chunk "${file}".`); // eslint-disable-line no-console
          }
      }
    });
  }
}; // eslint-disable-line eol-last